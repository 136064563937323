import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "2018 年の開発本部キックオフ合宿を行いました！@河口湖",
  "date": "2018-02-09T04:00:00.000Z",
  "slug": "entry/2018/02/09/130000",
  "tags": ["medley"],
  "hero": "./2018_02_09.png",
  "heroAlt": "kickoff 2018"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、開発本部の日下です。インターンを経て新卒としてメドレーに入り、現在は 1 年生エンジニアとして`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`オンライン診療アプリ CLINICS`}</a>{`の開発を担当しています。先日開発本部でキックオフ合宿を河口湖で行ってきましたので、詳細をレポートします。`}</p>
    <h1>{`年始のキックオフを合宿で行う意図`}</h1>
    <p>{`メドレーの開発本部では、チームビルディングを目的としてバーベキューなど全体でリフレッシュをする機会を年に数回設けています。`}</p>
    <p>{`そのリフレッシュの一環として行っているメドレーの「年始のキックオフ合宿」は、昨年から行われています。昨年のレポートは`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2017/02/14/180948"
      }}>{`こちら`}</a>{`にあります。`}</p>
    <p>{`メドレー開発本部全体のキックオフを合宿で行う意図は主に二つあります。`}</p>
    <h2>{`親睦を深める`}</h2>
    <p>{`一つ目の理由は親睦を深めることにあります。`}</p>
    <p>{`リフレッシュを兼ねた`}<strong parentName="p">{`一体感の醸成、連携・親密感を深める場`}</strong>{`を定期的に用意するようにしています。実際に合宿を終えて振り返ってみても、`}<strong parentName="p">{`合宿は日常より密な交流をする場として最高`}</strong>{`だったなと思います。`}</p>
    <h2>{`方向性の共有`}</h2>
    <p>{`二つ目の理由は方向性の共有のためです。`}</p>
    <p>{`昨年の合宿では、今後どういった方向を会社として、また各プロダクトとして目指して行くのかといった話を CTO ・平山から共有しました。普段の業務から離れた場所でこうした話に向き合えるという意味で、`}<strong parentName="p">{`共通の軸をより強く持つためには合宿が適切`}</strong>{`だと実感し、今年も合宿を行うことになりました。`}</p>
    <h1>{`合宿先・アクティビティの選定`}</h1>
    <p>{`いわゆる開発合宿ではなかったため、選定には次の項目を重視しました。`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`二十数名を収容できるキャパシティがあること`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`プライベートでも行きたい、思い出になるような場所であること`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`アクティビティが近くにあること`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`+α でバーベキューなどができること`}</strong></li>
    </ul>
    <p>{`上記の条件をすべて満たした場所を調査しましたが、「宿はあるがアクティビティがない」「アクティビティはあるが宿がない」のようになかなかマッチするところがなく苦労しました。`}</p>
    <p>{`最終的にはこれら全てにマッチした先として、宿泊先を`}<a parentName="p" {...{
        "href": "https://www.c-ban.com/"
      }}><strong parentName="a">{`河口湖カントリーコテージ`}</strong></a><strong parentName="p">{`さん`}</strong>{`に、アクティビティは`}<a parentName="p" {...{
        "href": "https://www.c-ls.jp/"
      }}><strong parentName="a">{`カントリーレイクシステムズ`}</strong></a><strong parentName="p">{`さん`}</strong>{`にお世話になることにしました。`}</p>
    <p>{`また、アクティビティに関しては、カヌー体験やほうとうづくりなど様々なものが用意されていましたが、次の観点から`}<a parentName="p" {...{
        "href": "https://www.c-ls.jp/%E6%A8%B9%E6%B5%B7%E6%B4%9E%E7%AA%9F%E4%BD%93%E9%A8%93"
      }}><strong parentName="a">{`洞窟探検`}</strong></a>{`を選択しました。`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`普段座りっぱなしなので、運動が行えること`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`新しい刺激を受けられるように、非日常感が体験できること`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`メンバーの普段見れない様子がでてきそうな、高揚感の高まる場所であること`}</strong></li>
    </ul>
    <h1>{`非日常な体験ができる洞窟探検`}</h1>
    <p>{`当日、河口湖駅に集合した後はまずアクティビティに挑みました。`}</p>
    <p>{`みんなで非日常を体験し、リフレッシュしてもらおうと選択したものでしたが、私も含め洞窟探検をした人はおらず、どういったものだろうとワクワクしながら富士の樹海を散策しました。`}</p>
    <p>{`今回体験した洞窟は富士の青木ヶ原樹海にある`}<a parentName="p" {...{
        "href": "https://www.yamanashi-kankou.jp/kankou/spot/p1_4905.html"
      }}>{`富士風穴`}</a>{`、初めて本格的な洞窟を見た我々は息を呑むような風景に圧倒されました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180208/20180208175850.jpg",
      "alt": "20180208175850.jpg"
    }}></img>
    <p><a parentName="p" {...{
        "href": "https://info.medley.jp/entry/2018/01/19/183500"
      }}>{`先日の週刊メドレー`}</a>{`で掲載された写真はまさに洞窟に入ろうとするメンバーでした。 思った以上に洞窟感のある洞窟が姿を表しおおーっとなりました。`}</p>
    <p>{`天井に頭をぶつけたり、手をついて怪我をしたりする可能性がありましたが、ヘルメット・手袋・真っ赤なつなぎといったグッズがレンタルできたので、すべて装着して洞窟に挑みました。`}</p>
    <p>{`洞窟の中は手すりや階段のない、自然そのままの洞窟らしく、岩がむき出しで天井が低かったりしたので思った以上に装備が役に立ちました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180208/20180208175926.jpg",
      "alt": "20180208175926.jpg"
    }}></img>
    <p>{`氷柱がある中、奥の方まで進むメンバー`}</p>
    <p>{`洞窟といえば氷柱！と思っていたのですが、ちょうど数日前に雨が降った影響で、普段より足元や天井から氷柱がたくさん生えていたので触ってみたり、初めての経験ばかりでした。`}</p>
    <p>{`氷が多い影響で滑りかけることもありましたが、ガイドさんと先行しているメンバーからの助言に助けられつつ進むことができました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180208/20180208180641.jpg",
      "alt": "20180208180641.jpg"
    }}></img>
    <p>{`無事に生還しました！`}</p>
    <p>{`普段は一緒に仕事をしている仲間と会社ではなく洞窟という空間で一緒にいるという不思議な状況と、適度な運動のせいか気分は高揚し、普段は見られないような同僚の一面を見ることができた良い経験になったと思います。`}</p>
    <h1>{`2018 年の開発本部キックオフ！`}</h1>
    <p>{`アクティビティで適度に運動をした後はコテージに移り、少しずつお酒を飲んでくつろぎ始めたタイミングで、2018 年のキックオフとして CTO ・平山によるプレゼンを行いました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180208/20180208180054.png",
      "alt": "20180208180054.png"
    }}></img>
    <p>{`昨年 2017 年は四つのプロダクトのうちジョブメドレー・ MEDLEY ・ CLINICS の大規模リニューアルがあり、大きな変化の年となりました。`}</p>
    <p>{`また、今後どういった会社にしていきたいか？医療業界にどういった形で我々が貢献していくのか？そしてそのために事業として、またプロダクトとして 2018 年はどういった体制で推進していくのかといった事柄が共有されました。`}</p>
    <p>{`なぜやるのか？どうやってやるのか？といった根本的な指針を背景を元に共有してもらうことで、今後の仕事における一つの軸ができたキックオフになったかと思います。`}</p>
    <p>{`私自身一年間 CLINICS に関わってきたため、肌身で感じてきた変化や苦労を思い出しつつ、プロダクト・事業が着実に成長していることを感じることができ、次の指針を確認する良い機会となりました。`}</p>
    <p>{`平山のプロダクト・事業・会社に対する熱い思いは`}<a parentName="p" {...{
        "href": "https://toppa.medley.jp/"
      }}>{`メドレー平山の中央突破`}</a>{`にも書かれていますので、ぜひご覧ください。`}</p>
    <h1>{`バーベキュー&飲み`}</h1>
    <p>{`キックオフ後はバーベキュー！そして飲み！`}</p>
    <p>{`火に強いメンバーは肉を焼いたり、料理が得意なメンバーは焼きそばやパスタを作っては食べて飲んで談笑し、思い思いに合宿を楽しんでいました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180208/20180208180114.jpg",
      "alt": "20180208180114.jpg"
    }}></img>
    <p>{`まずは肉を焼く火に強いメンバー`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180208/20180208180137.jpg",
      "alt": "20180208180137.jpg"
    }}></img>
    <p>{`談笑するメンバー（酔いが回りピントがボケ気味）`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180208/20180208180200.jpg",
      "alt": "20180208180200.jpg"
    }}></img>
    <p>{`思い思いにくつろぐメンバー`}</p>
    <p>{`余談ですが、上の画像で全員が着ている灰色のパーカーはキックオフ直前に配られたメドレーオフィシャルグッズの一つ、`}<a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley/post_articles/62275"
      }}>{`デザイン部長・マエダ`}</a>{`がデザインしたメドレーパーカーです。`}</p>
    <p>{`実はメドレーではパーカー以外にもうちわや T シャツ、絆創膏などグッズが増えています。`}</p>
    <p>{`気がついたら増えているので収集は大変ですが、今年はどういったグッズが作られるのか今から楽しみです。`}</p>
    <p>{`メンバー同士で飲んで食べてくつろぎながら、プロダクトへの思いやプライベートの話などを語り合う宴会が夜遅くまで続きました。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`いかがだったでしょうか？メドレー開発本部のキックオフ合宿の様子が少しでも伝われば幸いです。`}</p>
    <p>{`アクティビティやバーベキューを通じてより親密に、プレゼンを通じて認識の共有ができた良い合宿になったと思います。`}</p>
    <p>{`なお、今回の宿泊先は`}<a parentName="p" {...{
        "href": "https://www.c-ban.com/cottage.php"
      }}>{`大人数でも対応可能なコテージが用意され`}</a>{`、また Wi-Fi などの通信設備も整い、受付の方の対応も丁寧で、プライベートでも利用したくなる素敵な宿でした。`}</p>
    <p>{`開発合宿等の大人数での宿を探している方にご参考になれば幸いです。`}</p>
    <h1>{`最後に`}</h1>
    <p>{`最後になりましたが、今回の合宿の企画運営をリードしてくれた新居さんありがとうございました！`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180208/20180208180347.jpg",
      "alt": "20180208180347.jpg"
    }}></img>
    <p>{`来年またキックオフ合宿したいですね！（CTO が撮影してくれたので、CTO 不在の集合写真）`}</p>
    <p>{`メドレーでは、チームで新しい医療体験を提供するプロダクトを一緒に創るディレクターやエンジニア、デザイナーを募集しています。`}</p>
    <p>{`ご興味ある方は、まずはお気軽に話を聞きにお越しください！`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/recruit/creative.html"
      }}>{`https://www.medley.jp/recruit/creative.html`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      